import React, { useEffect, useState } from "react"
import CardPuntoDeVenta from "./CardPdv"
import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination } from "swiper"
import { IsMobileDevice } from "../../utils"

require("../../scss/components/ElMundialEnTusManos/_slidePdv.scss")


export default function SliderPuntoDeVenta({ pdvs, setZoom, setPosition }) {
    const [isMobile, setIsMobile] = useState(true)
    useEffect(() => {
        setIsMobile(IsMobileDevice())
    }, [])

    return (
        <>
            <Swiper
                modules={[Pagination]}
                spaceBetween={10}
                slidesPerView={isMobile ? 1 : 2}
                pagination={!isMobile && { clickable: true }}
                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log("slide change")}
            >
                {pdvs.length > 0 &&
                    (pdvs.sort((a, b) => {
                            return (b.promocion === "PROMO 2X1") - (a.promocion === "PROMO 2X1")
                        }).map(pdv => {
                            return (
                                <SwiperSlide>
                                    <CardPuntoDeVenta
                                        pdv={pdv}
                                        setZoom={setZoom}
                                        setPosition={setPosition}
                                    />
                                </SwiperSlide>
                            )
                        })
                    )}
            </Swiper>
        </>
    )

}
