
const greatPlaceStyle = {
  position: 'absolute',
  width: "83vw",
  height: "100%",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  overflow: "hidden",

  border: '10px solid rgb(156 40 32)',
  borderRadius: "40px",
  backgroundColor: 'white',
  textAlign: 'center',
  color: '#3f51b5',
  fontSize: 16,
  fontWeight: 'bold',
  padding: 4,
  
};

export {greatPlaceStyle};