import React, { useState, useEffect } from "react"
import SEO from "../components/seo"
import AgeGate from "../components/AgeGate"
import Cookies from "js-cookie"
import ElMundialEnTusManos from "../components/ElMundialEnTusManos/Main";

export default function ElMundial({ location, data }) {
  const [age, setAge] = useState(true)

  useEffect(() => {
    let cookie = Cookies.get("age")
    let local = sessionStorage.getItem("age")
    if (cookie || local) setAge(false)
  }, [])

  return !age ? (
    <ElMundialEnTusManos/>
  ) : (
    <AgeGate path={location?.pathname} />
  )
}

